import React from "react";

export default function LOGO(props) {
  return (
    <svg viewBox="0 0 87.88 53.93" {...props}>
      <g fill="currentColor">
        <path
          d="M72.48,68.44a2.43,2.43,0,0,0,1.11,1.92,7.18,7.18,0,0,0,3.08,1.22,23.19,23.19,0,0,0,5.52.2,13,13,0,0,0,3.5-.56,7.52,7.52,0,0,0,1.37-.66,1.64,1.64,0,0,0,.77-1.09A1.5,1.5,0,0,0,87,67.73a6,6,0,0,0-2.17-.65c-1.61-.18-3.22-.34-4.83-.47a42.84,42.84,0,0,1-7.49-1A10.29,10.29,0,0,1,68.93,64a5.45,5.45,0,0,1-2.27-5.13,6.71,6.71,0,0,1,3.4-5.33,13.55,13.55,0,0,1,5.07-1.86A24.44,24.44,0,0,1,85.52,52,11.84,11.84,0,0,1,91,54.94a6.85,6.85,0,0,1,2.14,4.58v.24a.45.45,0,0,1,0,.08H86.73a3,3,0,0,0-2.13-2.62,9.2,9.2,0,0,0-3.27-.73,23.2,23.2,0,0,0-5.2.17,6.24,6.24,0,0,0-2.37.77,1.93,1.93,0,0,0-1,1.3,1.35,1.35,0,0,0,.54,1.46,4.36,4.36,0,0,0,2,.78c1.41.21,2.83.4,4.25.53a54.76,54.76,0,0,1,8.06,1.08,13.15,13.15,0,0,1,3.2,1.2,5.87,5.87,0,0,1,1.81,8.88,9.56,9.56,0,0,1-4.05,2.88,19.43,19.43,0,0,1-5.25,1.25,29.5,29.5,0,0,1-6.44,0,17.06,17.06,0,0,1-7-2.14A7.79,7.79,0,0,1,66.58,71a6,6,0,0,1-.44-2.56Z"
          transform="translate(-6.06 -23.03)"
        />
        <path
          d="M91.93,42.44v5.11H68.11V23.05h23.5c.25,0,.35,0,.34.32,0,1.5,0,3,0,4.5,0,.09,0,.18,0,.3H74.12V32.7H91.61v4.81H74.12v4.93Z"
          transform="translate(-6.06 -23.03)"
        />
        <path
          d="M45.83,71.29h17.8v5.09H39.81V51.89H63.63V57H45.83v4.55H63.31v4.8H45.83Z"
          transform="translate(-6.06 -23.03)"
        />
        <path
          d="M45.81,47.55h-6V23.06h6V32.7H57.61V23.06h6V47.55h-6v-10H45.81Z"
          transform="translate(-6.06 -23.03)"
        />
        <path
          d="M36.13,23.06v5.09H24.75v19.4h-6V28.18H7.38V23.06Z"
          transform="translate(-6.06 -23.03)"
        />
        <path
          d="M21.74,63.43l1.68-2.25L30.2,52.1a.45.45,0,0,1,.4-.23h6.58l.27,0-.21.29L24.91,68.24a.84.84,0,0,0-.16.48q0,3.66,0,7.31v.35h-6V76c0-2.42,0-4.83,0-7.25a.87.87,0,0,0-.17-.51Q12.41,60.21,6.26,52.17l-.2-.28.28,0h6.54a.45.45,0,0,1,.41.22l8.22,11C21.57,63.22,21.65,63.31,21.74,63.43Z"
          transform="translate(-6.06 -23.03)"
        />
      </g>
    </svg>
  );
}
